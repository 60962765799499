import Img from "gatsby-image";
import React from 'react';
import styled from "styled-components";


const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 515px;
	min-width: 365px;
	color: white;
	box-shadow:  0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.13);
	border-radius: 10px;
	padding: 26px;
	z-index: 3;
	margin: 16px 20px 0 20px;
	background-color: white;
	

	@media (max-width: 768px) {
     min-width: 165px;
	 margin: 20px 0 20px 0;
	 height: auto;
  }
`

const CardFooter = styled.div`
	min-height: 200px;
	font-size: 14px;
	color: black;
	font-weight: 400;
	margin-top: 16px;
	@media (max-width: 768px) {
   	height: 100%;
  }
`

const CardImage = styled.div`
	width: 100%;
	object-fit: fill;
	min-height: 185px;
`

const CardSubtitle = styled.p`
   line-height: 24px; 
   letter-spacing: 0;
   overflow: hidden;
`;

const Card = (props) => {

	return (
		<CardWrapper>
			<CardImage>
				<Img fluid={props.fluidImg} loading="eager" alt="a product image of the project" />
			</CardImage>
			
			<CardFooter>
				<h3 style={{margin: 0, marginBottom: 16, width: '100%'}}>{props.title}</h3>
				<CardSubtitle>{props.subtitle}</CardSubtitle>
			</CardFooter>
		</CardWrapper>
	);
}


export default Card;
