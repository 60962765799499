import * as React from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";


const ContactFormWrapper = styled.div`
    z-index: 20;
`
const InputStyle = styled.input`
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    padding: 6px 0px 6px 0px;
    width: 200px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 756px) {
        width: 100%;
        margin-top: 26px;
    }
`

const TextArea = styled.textarea`
    outline: none;
    width: 520px;
    height: 100px;
    border: 1px solid black;
    padding: 6px;
    font-size: 14px;
    resize: none;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 756px) {
        width: 100%;
    }
`

const Button = styled.button`
    width: 200px;
    height: 50px;
    font-weight: 500;
	font-size: 16px;
    font-family: 'Poppins', sans-serif;
    background-color: #4FBC61;
    border: none;
    color: white;
    margin-top: 26px;

`

const Inputs = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 535px;
    @media (max-width: 756px) {
        width: 100%;
        flex-direction: column;
    }
`

const MidSectionPreTitle = styled.h6`
   color: #4fbc61;
   margin: 0;
   margin-bottom: 26px;
   font-weight: 500;
 `

const ContactForm = () => {
    const {t} = useTranslation();

    return (
        <ContactFormWrapper>
            <MidSectionPreTitle data-sal="slide-up" data-sal-duration="500" data-sal-delay="400" data-sal-easing="easeOutQuint" >{t('contact.us')}</MidSectionPreTitle>
            <form name="contact" method="POST" data-netlify="true" action="thank-you"  data-sal="slide-up" data-sal-duration="500" data-sal-delay="400" data-sal-easing="easeOutQuint"
                 type="application/x-www-form-urlencoded" value="contact">
                <Inputs>
                    <InputStyle required={true} type="text" name="name" placeholder={t('your.name')} />
                    <InputStyle required={true} type="email" name="email" placeholder={t('email.address')} />
                    <input type="hidden" name="form-name" value="contact" />
                </Inputs>


                <div style={{ marginTop: 56 }}>
                    <TextArea required={true} name="message" placeholder={t("tell.me.more.about")}></TextArea>
                    <div data-netlify-recaptcha="true"></div>
                </div>
                <p>
                    <Button type="submit">{t('contact')}</Button>
                </p>
            </form>
        </ContactFormWrapper>
    )
}

export default ContactForm;