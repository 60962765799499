import { graphql } from "gatsby";
import { Link } from 'gatsby-plugin-react-i18next';
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FooterWrapper = styled.section`
height: 240px;
background-color: black;
display: flex;
flex-direction: column;
justify-content: flex-end;
width: 100%;
position: relative;
background-color: #493829;

@media (max-width: 768px) {
  height: 100%;
}

@media (max-width: 1150px) {
  height: 100%;
}
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  padding: 0 80px 0 80px;

@media (max-width: 768px) {
  padding-left: 24px;
}

@media (max-width: 1150px) {
  margin-bottom: 75px;
}
`;

const FooterBottomItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  font-size: 14px;

  @media (max-width: 1150px) {
    flex-direction: column;
  }


  @media (max-width: 1150px) {
    margin: 16px;
  }
`;

const Block = styled.div`
  display: block;
  margin-top: 0;
  margin-left: 16px;
  color: #4FBC61;

  @media (max-width: 1150px) {
    margin-top: 16px;
    margin-left: 0;
  }
`

const OfficeSubtitle = styled.p`
  color: white;
  margin: 0;
  line-height: 22px;
`


const Footer = (props) => {
  const {t} = useTranslation();
  return (
    <FooterWrapper>
      <FooterBottom>
        <FooterBottomItems>
          <Block>&copy; 2024 SpringTree B.V.
              <OfficeSubtitle><Link style={{textDecoration: 'none', color: 'inherit', margin: '1em 0 1em 0'}} to="/privacy">{t('privacy.policy')}</Link></OfficeSubtitle>
          </Block>

          <Block>
            Almere Office
            <OfficeSubtitle>Landdrostdreef 124</OfficeSubtitle>
            <OfficeSubtitle>Tower19, 16th floor</OfficeSubtitle>
            <OfficeSubtitle>1314 SK Almere</OfficeSubtitle>
            <OfficeSubtitle>The Netherlands</OfficeSubtitle>
          </Block>

          <Block>
            {t('contact.information')}
            <OfficeSubtitle>
              <a href="mailto:info@springtree.nl" style={{textDecoration: 'none', color: 'inherit'}}>info@springtree.nl</a>
            </OfficeSubtitle>
            <OfficeSubtitle>
              <a href="tel:+31858227246" style={{textDecoration: 'none', color: 'inherit'}}>+31 (0)85 8227246</a>
            </OfficeSubtitle>  
          </Block>

          <Block>
            {t('business.information')}
          <OfficeSubtitle>{t('chamber.of.commerce')}: 64820025</OfficeSubtitle>
          <OfficeSubtitle>IBAN: NL84 ABNA 0407 765 999</OfficeSubtitle>
          <OfficeSubtitle>{t('vat')} {t('number')}: NL855870047B01</OfficeSubtitle>
          </Block>
        </FooterBottomItems>
      </FooterBottom>
    </FooterWrapper>
  );
};


export default Footer;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
