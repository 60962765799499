import { graphql } from "gatsby";
import Img from "gatsby-image";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import styled from "styled-components";
import Card from "../components/card";
import ContactForm from '../components/contact-form';
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from '../images/favicon.png';
import { navigate } from 'gatsby';

// Images
const TopRightTree = styled.div`
   position: absolute;
   top: -19px;
   right: -250px;
   width: 950px;

   @media (max-width: 768px) {
    right: -265px;
    width: 450px !important;
  }

  @media (max-width:1180px ) {
    width: 760px;
  }

  @media (max-width: 900px) {
    width: 600px;
    right: -245px;
  }
 `;

const LeftUnderHeroTree = styled.div`
   position: absolute;
   top: 650px;
   left: -380px;
   width: 915px;
   z-index: 0;

   @media (max-width: 768px) {
     left: -265px;
     width: 615px;
     top: 800px;
  }

  @media (max-width: 1150px) {
    top: 900px;
  }
 `;

const RightMidTree = styled.div`
   position: absolute;
   top: 1400px;
   right: -380px;
   width: 900px;

   @media (max-width: 768px) {
     top: 1600px;
   }
 `;

const RightBottomTree = styled.div`
   position: absolute;
   top: 2800px;
   right: -380px;
   width: 900px;
   z-index: 0;

   @media (max-width: 768px) {
     right: -286px;
     width: 615px;
  }
 `;

const LeftBottomTree = styled.div`
   position: absolute;
   top: 4000px;
   left: -380px;
   width: 900px;

   @media (max-width: 768px) {
     top: 4300px;
     left: -280px;
     width: 615px;
  }
 `;

const MidBgIcons = styled.div`
   position: absolute;
   top: 1800px;
   left: 0;
   z-index: 1;
   margin-left: auto;
   margin-right: auto;
   right: 0;
   width: 1560px;

   @media (max-width: 768px) {
    width: 100%;
  }
 `

const TopBgIcons = styled.div`
   position: absolute;
   top: 750px;
   left: 0;
   z-index: 1;
   margin-left: auto;
   right: 10%;
   width: 860px;

   @media (max-width: 768px) {
    width: 100%;
  }
 `

// Reusable
const MonoFont = styled.span`
 font-family: "Zilla Slab Highlight", monospace;
 color: #4fbc61;
 `;

////////////////////////////////////////

// Components & Sections
const HomePageWrapper = styled.div`
   background-color: white;
   margin: 0;
   overflow: hidden;
   position: relative;
 `;

const HomePageInner = styled.div`
   max-width: 1560px;
   margin-left: auto;
   margin-right: auto;
   @media (max-width: 768px) {
     max-width: 100%;
  }
 `

// Header
const Heading = styled.section`
   min-height:  30vh;
   margin-top: 50px;
   padding: 160px;
   margin-bottom: 100px;

   @media (max-width: 768px) {
   min-height: 100%;
   padding: 26px;
   margin-bottom: 50px;
  }
 `;

const HeroContent = styled.div`
   max-width: 650px;
   line-height: 36px;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 1500px) {
      max-width: 400px;
    }
 `;

const HeroTitle = styled.h1`
   font-size: 40px;
   line-height: 50px;
   letter-spacing: 0;
   font-weight: 400;
   margin-left: auto;
   margin-top: 0;
   z-index: 10;
   position: relative;

   @media (max-width: 768px) {
    font-size: 38px;
    margin-top: 80px;
  }
 `

const HeadingImage = styled.div`
   width: 785px;
   grid-column: 2/3;
   position: absolute;
   right: 0;
   top: 80px;

   @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1500px) {
    width: 600px;
  }

  @media (max-width:1180px ) {
    width: 400px;
  }

  @media (max-width: 900px) {
    width: 300px;
  }
 `;

// Mid Section
const MidSection = styled.section`
   padding: 0 80px 0 80px;
   z-index: 22;
    margin-top: 200px;
   @media (max-width: 768px) {
    padding: 0 26px 0 26px;
  }
 `;

const MidSectionImageBlock = styled.div`
   display:  flex;
   max-width: 1560px;
   flex-direction: row;

   @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
 `

const BlogSectionImageBlock = styled.div`
   display:  flex;
   max-width: 1560px;
   flex-direction: row;

   @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;

  }
 `

const MidSectionImage = styled.div`
   width: 50%;

   @media (max-width: 768px) {
    width: 100%;
    margin-top: 46px;
  }
 `

const BlogSectionImage = styled.div`
width: 50%;
cursor: pointer;
@media (max-width: 768px) {
 width: 100%;
 margin-top: 46px;
}
`

const MidSectionTextContent = styled.div`
   display: flex;
   margin-top: 0;
   flex-direction: column;
   margin-left: 86px;
   width: 50%;
   z-index: 2;

   @media (max-width: 768px) {
    margin-top: 46px;
    margin-left: 0;
    width: 100%;
  }
 `

const BlogSectionTextContent = styled.div`
   display: flex;
   margin-top: 0;
   flex-direction: column;
   margin-right: 86px;
   width: 50%;
   z-index: 2;

   @media (max-width: 768px) {
    margin-top: 46px;
    margin-left: 0;
    width: 100%;
  }


 `


const MidSectionPreTitle = styled.h6`
   color: #4fbc61;
   margin: 0 0 16px 0;
	 font-weight: 500;
 `
const MidSectionTitle = styled.h2`
   font-size: 40px;
   font-weight: 400;
   line-height: 42px;
   margin-top: 12px;
 `

const UseCases = styled.div`
   padding: 0 80px 0 80px;
   margin-top: 200px;
   z-index: 10;
  position: relative;
   @media (max-width: 768px) {
    padding: 0 26px 0 26px;
    margin-top: 120px;
    margin-bottom: 80px;
  }
 `
const UseCasesPreTitle = styled.h6`
  color: #4fbc61;
  margin-left:  20px;
  margin-bottom: 20px;
  font-size: 21px;
	font-weight: 500;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
 `

const Cards = styled.div`
   display: grid;
   grid-template: 1fr / 1fr 1fr 1fr;
   justify-self: center;
   justify-content: center;
   align-items: center;
   z-index: 5;

   @media (max-width: 1180px) {
    grid-template: 1fr / 1fr 1fr;
    justify-self: center;
  }

  @media (max-width: 768px) {
    grid-template: 1fr / 1fr;
    justify-self: center;
  }
 `


// Bottom Section
const BottomSection = styled.section`
   padding: 0 80px 0 80px;
   max-width: 1560px;
   margin-top: 200px;
   margin-left: auto;
   margin-right: auto;

   @media (max-width: 768px) {
    padding: 0 26px 0 26px;
    max-width: 100%;
  }
 `;

const OurClients = styled.div`
   max-width: 1560px;
   padding:  0 80px 0 80px;
   margin-bottom:200px;
   z-index: 22;

   @media (max-width: 768px) {
     max-width: 100%;
     padding: 0 24px 0 24px;
     margin-bottom: 80px;
  }
 `;

const WhoWeAreWrapper = styled.div`
   max-width: 1560px;
   padding: 0 80px 0 80px;
   margin-top: 200px;
   margin-bottom: 200px;

   @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 24px 0 24px;
    margin-top: 80px;
    margin-bottom: 80px;
  }
 `;
const WhoWeAreImage = styled.div`
 margin-top: 200px;
 margin-bottom: 200px;

 @media (max-width: 768px) {
  margin-top: 80px;
  margin-bottom: 80px;
}
 `;

const ContactUs = styled.div`
  display: grid;
  margin-bottom: 80px;
  margin-top: 200px;
  grid-gap: 56px;
  grid-template: 1fr / 1fr 1fr;

  @media (max-width: 1150px) {
    grid-template: 1fr 1fr / 1fr !important;
    grid-gap: 56px;
    width: 100%;
     justify-items: center;
}
`
// Brands
const BrandsWrapper = styled.div`
 display: flex;
  flex-direction: row;
 justify-content: space-evenly;
 align-items: center;
 margin-top: 36px;
 margin-bottom: 36px;

 @media (max-width: 768px) {
    flex-direction: column;
  }
 `;

const BrandLogo = styled.div`
 width: 225px;
 height: 100%;
 `;

//Reusable
const TextBlock = styled.p`
 font-size: 14px;
 line-height: 24px;
 letter-spacing: 0;
 margin: 0;
 max-width: 600px;
 `;

const TextBlockDynamic = styled.p`
  max-width: 1000px;;
  line-height: 42px;
  font-size: 28px;

 @media (max-width: 768px) {
   max-width: 100%;
  }
 `

const TextBlockDynamicFooter = styled.p`
max-width: 800px;;
line-height: 36px;
font-size: 28px;
margin-top: 0;


@media (max-width: 768px) {
 max-width: 100%;
 margin-left: 0;
}
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 120px 0 120px 0;
`

const SliderInner = styled.div`
  max-width: 800px;
  @media (max-width: 1150px) {
   width: 100%;
  }
`

const SliderWriterName = styled.p`
  font-size: 12px;
`


const HomePage = ({ data }) => {

  const SliderSettings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  };


  const { t } = useTranslation();
  return (
    <HomePageWrapper>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>SpringTree</title>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="canonical" href="https://springtree.eu" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="twitter:card" content="summary"></meta>
        <meta name="google-site-verification" content="JkFfswurtlPYtACWbaHO7Md91WcqIsiRvTD5p5zg_34" />
      </Helmet>

      <HomePageInner>

        {/* Background trees  */}
        <TopRightTree >
          <StaticImage loading="eager" placeholder="none" src='../images/top-right-tree.png' alt="leaves" />
        </TopRightTree>

        <LeftUnderHeroTree>
          <StaticImage loading="eager" placeholder="none" src="../images/left-under-hero-tree.png" alt="leaves" />
        </LeftUnderHeroTree>

        <RightMidTree >
          <StaticImage loading="eager" placeholder="none" src="../images/mid-right-tree.png" alt="leaves" />
        </RightMidTree>

        <RightBottomTree>
          <StaticImage loading="eager" placeholder="none" src="../images/mid-right-tree.png" alt="leaves" />
        </RightBottomTree>

        <LeftBottomTree>
          <StaticImage loading="eager" placeholder="none" src="../images/left-under-hero-tree.png" alt="leaves" />
        </LeftBottomTree>

        <MidBgIcons>
          <Img loading="eager" fluid={data.midBgIcons.childImageSharp.fluid} />
        </MidBgIcons>

        <TopBgIcons>
          <Img loading="eager" fluid={data.topBgIcons.childImageSharp.fluid} />
        </TopBgIcons>


        {/* Heading & Hero */}
        <Heading>
          <HeroContent>
            <HeroTitle>
              {t("where")} <MonoFont>{t("software")}</MonoFont> {t("grows.and.ideas")}
            </HeroTitle>
            <TextBlock>
              {t('hero.block')}
            </TextBlock>
          </HeroContent>

          <HeadingImage>
            <StaticImage loading="eager" src="../images/hero-image.png" placeholder="none" alt="a laptop and some humans around it cartoon" />
          </HeadingImage>
        </Heading>

        {/* Sections */}

        <MidSection >
          <MidSectionImageBlock>
            <MidSectionImage>
              <StaticImage loading="eager" src="../images/analytics.png" placeholder="none" alt="Analytics screen with cartoon humans pointing at it" />
            </MidSectionImage>

            <MidSectionTextContent >
              <MidSectionPreTitle>{t('problem.solving')}</MidSectionPreTitle>
              <MidSectionTitle>{t('mid.section.title')}</MidSectionTitle>
              <TextBlock>
                {t('mid.section.block')}</TextBlock>
            </MidSectionTextContent>
          </MidSectionImageBlock>

          <UseCases>
            <UseCasesPreTitle data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">{t('use.cases.title')}</UseCasesPreTitle>
            <Cards>
              <Card data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint" title="Doenkids x KidsKonnect" subtitle={t('doenkids.usecase')} fluidImg={data.doenkidsLaptop.childImageSharp.fluid} />
              <Card data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint" title="New Black x EVA" subtitle={t('newblack.usecase')} fluidImg={data.allDevices.childImageSharp.fluid} />
              <Card data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint" title="Efice x Catching Data" subtitle={t('efice.usecase')} fluidImg={data.catchingData.childImageSharp.fluid} />
            </Cards>

          </UseCases>

        </MidSection>

        <BottomSection>
          <OurClients>
            <MidSectionPreTitle data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">{t('our.clients')}</MidSectionPreTitle>
            <TextBlockDynamic data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              {t('clients.block')}
            </TextBlockDynamic>
          </OurClients>



          <BrandsWrapper>
            <BrandLogo data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              <StaticImage src="../images/customer-logos/newblack.png" placeholder="none" alt="newblack company logo" />
            </BrandLogo>
            <BrandLogo data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              <StaticImage src="../images/customer-logos/ednl.png" placeholder="none" alt="ednl company logo" />
            </BrandLogo>
            <BrandLogo data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              <StaticImage src="../images/customer-logos/kidskonnect.png" placeholder="none" alt="kidskonnect company logo" />
            </BrandLogo>
            <BrandLogo data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              <StaticImage src="../images/customer-logos/m-catch-logo.png" placeholder="none" alt="efice company logo" />
            </BrandLogo>
          </BrandsWrapper>


          <SliderWrapper >
            <SliderInner>
              <Slider {...SliderSettings}>
                <div>
                  <h5>
                    Efice
                  </h5>
                  <p>
                    {t('efice.testimonial')}
                  </p>
                  <SliderWriterName>
                    Teun de Boer
                  </SliderWriterName>
                </div>
                <div>
                  <h5>
                    New Black
                  </h5>
                  <p >
                    {t('newblack.testimonial')}
                  </p>
                  <SliderWriterName>
                    Lub Ten Napel
                  </SliderWriterName>
                </div>
                <div>
                  <h5>
                    Doenkids x KidsKonnect
                  </h5>
                  <p >
                    {t('doenkids.testimonial')}
                  </p>
                  <SliderWriterName>
                    Trudy van Iwaarden
                  </SliderWriterName>
                </div>
              </Slider>
            </SliderInner>
          </SliderWrapper>


          <WhoWeAreWrapper>
            <MidSectionPreTitle data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">{t('who.we.are')}</MidSectionPreTitle>
            <TextBlockDynamic data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint">
              {t('who.we.are.block')}
            </TextBlockDynamic>
            <WhoWeAreImage>
              <StaticImage placeholder="none" src="../images/collage.png" alt="a collage of SpringTree employees and family together" />
            </WhoWeAreImage>
          </WhoWeAreWrapper>

          <MidSection>
            <BlogSectionImageBlock>
              <BlogSectionTextContent >
                <MidSectionPreTitle>{t('our.blog')}</MidSectionPreTitle>
                <MidSectionTitle>{t('our.blog.title')}</MidSectionTitle>
                <TextBlock>
                  {t('our.blog.text')}</TextBlock>
              </BlogSectionTextContent>
              <BlogSectionImage onClick={() => navigate('/blog')}>
                <StaticImage loading="eager" src="../images/blog.png" placeholder="none" alt="multiple of our posts from the blog" />
              </BlogSectionImage>
            </BlogSectionImageBlock>
          </MidSection>

          <ContactUs id="contact">
            <TextBlockDynamicFooter data-sal="slide-up" data-sal-duration="500" data-sal-easing="easeOutQuint"
            >
              {t('contact.us.block')}
            </TextBlockDynamicFooter>
          </ContactUs>
        </BottomSection>
      </HomePageInner>
      <Footer />
    </HomePageWrapper>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    doenkidsLaptop: file(
      relativePath: { eq: "doenkids-laptop.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    midBgIcons: file(
      relativePath: { eq: "bg-icons-mid.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1560, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    catchingData: file(
      relativePath: { eq: "catching-data.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    topBgIcons: file(
      relativePath: { eq: "bg-icons-top.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1560, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allDevices: file(
      relativePath: { eq: "all-devices.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default HomePage;
